import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import service from '@/utils/request.js'
Vue.prototype.$request = service
import secondService from '@/utils/secondRequest.js'
Vue.prototype.$secondRequest = secondService
Vue.config.productionTip = false
import upload from '@/utils/upload.js'
Vue.prototype.$upload = upload
import axios from 'axios';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
