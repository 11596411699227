import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path:'/middleEentry',
		name:'middleEentry',
		component:() => import('@/views/middleEentry.vue')
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/index.vue'),
		redirect: '/index/personalData',
		children: [{
				path: 'personalData',
				component: () => import('@/views/personalData')
			},
			{
				path: 'editPersonalData',
				component: () => import('@/views/editPersonalData')
			},
			{
				path: 'uploadArticle',
				component: () => import('@/views/uploadArticle')
			},
			{
				path: 'history',
				component: () => import('@/views/history')
			},
			{
				path: 'editArticle',
				component: () => import('@/views/editArticle')
			},
			{
				path: 'editPassword',
				component: () => import('@/views/editPassword')
			},
			{
				path: 'earnings',
				component: () => import('@/views/earnings')
			},
			{
				path: 'home',
				component: () => import('@/views/home')
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((to, from, next) => {
	if(to.path === '/middleEentry'){
		next()
	}else{
		if (sessionStorage.getItem('token')) {
			next()
		} else {
			if (to.path !== '/') {
				next('/')
			} else {
				next()
			}
		}
	}
})

export default router