import axios from "axios";
import {
	Message,
	Loading
} from "element-ui";
import Vue from 'vue'
const secondService = axios.create({
	baseURL: process.env.VUE_APP_BASEURL,
	timeout: 10 * 1000
})
let loadingInstance = null
secondService.interceptors.request.use(config => {
	loadingInstance = Loading.service({
		lock: true,
		text: 'Loading',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	const token = sessionStorage.getItem('token')
	if (token) {
		config.headers.token = token
	}
	return config
}, err => {
	return Promise.reject(err)
})

secondService.interceptors.response.use(response => {
	Vue.nextTick(() => {
		loadingInstance.close();
	})
	return response.data
}, error => {
	Vue.nextTick(() => {
		loadingInstance.close();
	})
	if (error.response.status) {
		switch (error.response.status) {
			case 400:
				error.message = '错误请求'
				break;
			case 401:
				error.message = '凭证过期，请重新登录'
				break;
			case 403:
				error.message = '拒绝访问'
				break;
			case 404:
				error.message = '请求错误,未找到该资源'
				window.location.href = "/NotFound"
				break;
			case 405:
				error.message = '请求方法未允许'
				break;
			case 408:
				error.message = '请求超时'
				break;
			case 500:
				error.message = '服务器端出错'
				break;
			case 501:
				error.message = '网络未实现'
				break;
			case 502:
				error.message = '网络错误'
				break;
			case 503:
				error.message = '服务不可用'
				break;
			case 504:
				error.message = '网络超时'
				break;
			case 505:
				error.message = 'http版本不支持该请求'
				break;
			default:
				error.message = `连接错误${error.response.status}`
		}
	} else {
		if (JSON.stringify(error).includes('timeout')) {
			Message.error('服务器响应超时，请刷新当前页')
		}
		error.message = '连接服务器失败'
	}
	Message.error(error.message)
	if (error.response.status == 401) {
		setTimeout(() => {
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('userinfo')
			window.location.href = '/'
		}, 500)
	}
	return Promise.resolve(error.response)
})

export default secondService